@-moz-document url-prefix() {
  @media print {
    body, div {
      display: block !important;
    }
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.dateInput{
  font-family: Roboto;
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  width: 100%;
  border: 1px solid #CFCDCE;
  border-radius: 4px;
  box-sizing: border-box;
  color: #8C878A;
  padding: 7px;
}

.react-datepicker-popper {
  z-index: 1000 !important;
}

.bulletin-dateInput {
  height: 38px;
  min-width: 450px;
}

.react-datepicker__close-icon::after {
  color: #8C878A !important;
  background: none !important;
  font-size: 16px !important;
}

.react-datepicker__day--selected {
  background: #82A6DD !important;
}
.react-datepicker__day--keyboard-selected {
  background: #82A6DD !important;
}
.react-datepicker__time-list-item--selected {
  background: #82A6DD !important;
}

.dateInputDirty{
  border: 1px solid #53C0D5;
  color: #53C0D5;
}

.dateInputError{
  border: solid 1px red !important;
  background: #F9CCCA !important;
  color: black;
}

.editor-class {
  background: #FFFFFF;
  font-family: Roboto, sans-serif;
  font-weight: 300;
  font-size: 18px;
  margin: 0;
  padding: 8px;
}

.editor-class div {
  margin: 0;
}

.editor-class h1 {
  font-family: Maven Pro;
  font-weight: normal;
  font-style: normal;
  font-size: 32px;
  margin: 0;
}

.editor-class h2 {
  font-family: Maven Pro;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  margin: 0;
}

.editor-class h3 {
  font-weight: 500;
  font-size: 18px;
  margin: 0;
}

.editor-class a {
  color: #1a72c8;
  text-decoration: none;
}

.editor-class a:visited {
  color: #1a72c8;
}

.react-datepicker-wrapper {
  width: 100%;
}

